<template>
	<div class="mainTem">
		<div class="buyerHeader">
			<h3 class="buyerHeaderTit">{{$t('i18nn_b00576fed9b3a1f2')}}<!-- <el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
				<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
			</el-tooltip> -->
			</h3>
		</div>

		<div class="wh-filter-con">
			<!-- <div class="wh-filter-item">
			 		 <span>{{$t('hytxs0000060')}}</span>
			 		 <cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
			 </div> -->
			<div class="wh-filter-item">
				<span>{{$t('i18nn_b00576fed9b3a1f2')}}</span>：
				<el-input
					type="text"
					v-model="filterData.userName"
					size="small"
					clearable
					@keyup.enter.native="initData()"
					:placeholder="$t('FormMsg.Please_Enter')"
					maxlength="50"
					style="width: 180px;"
				/>
			</div>
			<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
		</div>

		

		<!-- <div class="wh-action-con"> -->
			<!-- <el-button type="success" @click="openDioalog" size="small" icon="el-icon-plus">{{$t('c0246c55b9cac963')}}</el-button> -->
			<!-- <el-button type="warning" @click="openExcelDialog" size="small" icon="el-icon-upload">{{$t('Storage.skuInfo.Import_SKU')}}</el-button> -->
		<!-- </div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			
			<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true" 
				:height="$store.state.tableMaxHeight2"  @selection-change="handleSelectionChange"
					@row-click="handleCurrentChange" style="width: 100%" size="small">
				
				<el-table-column type="selection" fixed="left" align="center" width="50" v-if="multiple">
				</el-table-column>
				
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
				
				<el-table-column prop="userName" :label="$t('i18nn_b00576fed9b3a1f2')"></el-table-column>
				<el-table-column prop="roleName" :label="$t('i18nn_a36eda2f87d65f7b')"></el-table-column>

			
				<el-table-column prop="userId" :label="'userId'"></el-table-column>

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="100px" fixed="right">
					<template slot-scope="scope">
						<!-- <div v-if="isSel"> -->
							<el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button>
						<!-- </div> -->
						<!-- <div v-else> -->
						<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button> -->
						<!-- <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button> -->
						<!-- </div> -->
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		
		
		<el-button type="primary" icon="el-icon-check" @click="sureAction()" v-if="multiple">{{$t('i18nn_760f75dc397a9cb8')}}</el-button>
		
	</div>
</template>
<script>
// import CommodityCateLinkage from '@/components/Common/CommodityCateLinkage.vue';
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
// import barcode from '@/components/Common/barcode.vue';
// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	components: {
		//   CommodityCateLinkage,
		// barcode,
		// cusSelFuzzy
	},
	props:{
		openTime: {},
	  // mobile:"",
	  multiple:{
	    default: function() {
	      return false
	    },
	    type: Boolean
	  },
	},
	data() {
		return {
			// cusUserId: '',
			
			// UserInfo: this.$store.getters.getUserInfo,
			// dialogFormVisible: false,
			// dialogFormStatus: 0, //0-新增，1-修改

			// CateValue: [],
			loading: false,
			// form: {
			// 	id: null, //"数据ID",
			// 	bckId: '', //
			// 	whNo: '' //
			// },

			// formRules: {
			// 	bckId: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	whNo: [{ required: true, message: this.$t('FormMsg.Please_select'), trigger: 'change' }]
			// },
			multipleSelection:[],
			loading_load: false,
			tableData: [],
			//分页数据
			pagination: this.$Utils.defaultPagination(),
			// selectOption: {
			// 	wh_no:[]
			// },
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc', //desc降序，asc升序
	
				userName: ''
			}
		};
	},
	watch: {
		openTime: function(newVal, oldVal) {
			console.log('openTime');
			this.initData();
			//初始化
			// this.dialog = true;
		}
	},
	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();
		this.initData();
	},
	methods: {

		initData() {
			this.pagination.current_page = 1;
			// this.currentSel = {};
			this.multipleSelection = [];
			this.getPageData();
			// this.getDicData();
		},

		//查询数据
		serPageData() {
			this.pagination.current_page = 1;
			this.getPageData();
		},
		
		//请求分页数据
		getPageData() {
			// let _this = this;
			this.loading_load = true;

			this.$http
				.post(this.$urlConfig.AccountUserPageList, {
					// sortAsc: this.filterData.sortAsc,
					// orderBy: this.filterData.orderBy,
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目

					userName: this.filterData.userName ? this.filterData.userName : null
				})
				.then(({ data }) => {
					console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);
					
					this.loading_load = false;
					//表格显示数据
					this.tableData = data.rows;
					//当前数据总条数
					this.pagination.total = parseInt(data.total);
					//当前页数
					// this.pagination.current_page = parseInt(data.current);
					//当前页条数
					// this.pagination.page_size = parseInt(data.size);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},
		
		//选择数据后回调
		selRow(event, row) {
			event.stopPropagation();
			this.$emit('selectRow', row);
		},
		
		sureAction(){
			this.$emit('sure', this.multipleSelection);
			// this.dialog = false;
		},
		//选择行
		handleCurrentChange(row, event, column) {
			// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
		},
		//多选
		handleSelectionChange(val) {
			console.log(val);
			this.multipleSelection = val;
			// this.$emit('SelChange', val);
		},
		
		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);

		// 	// this.loading_load = true;
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_no'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				this.selectOption.wh_no = data.data['wh_no'];
		// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('tips.submitError');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('tips.submitRequestError'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import url(@/assets/css/client_module.less);

// /deep/ .el-input-number,
//   .el-select {
//     // width: 100px;
//     .el-input__inner {
//       text-align: left;
//     }
//   }
// .form_msg {
//   color: #e6a23c;
// }
</style>
